<div class="content">
  <header class="header">
    <div class="column index">
      <div class="breadcrumbs-panel">
        <app-breadcrumbs></app-breadcrumbs>
        <app-help-button class="help-button" helpKey="ogr-details"></app-help-button>
      </div>
      <dropdown *ngIf="data?.length"
                class="header-item"
                [items]="availableSeasons"
                [current]="selectedSeason"
                handle="ogrDetailsSeasonDropdown"
                [highlightSelected]="true"
                (currentChange)="selectSeason($event)">
        <button class="button transparent tiny rounded bordered">
          Season: {{ selectedSeason.label }}
          <i class="fas fa-chevron-right right"></i>
        </button>
      </dropdown>
      <dropdown *ngIf="data?.length && availableForecasts?.length"
                class="header-item"
                [items]="availableForecasts"
                [current]="selectedForecast"
                handle="ogrDetailsForecastDropdown"
                [highlightSelected]="true"
                (currentChange)="selectForecast($event)">
        <button class="button transparent tiny rounded bordered">
          Forecast: {{ selectedForecast.label }}
          <i class="fas fa-chevron-right right"></i>
        </button>
      </dropdown>
    </div>
    <div *ngIf="tabs?.length" class="column data-category two-line-tabs">
      <div class="download-report-button-group">
        <button class="button transparent tiny rounded bordered"
                (click)="openOgrReport()">
          <i class="fas fa-download"></i>
          OGR Report
        </button>
      </div>
      <div class="data-category-content">
        <app-simple-tabs *ngIf="dataCategoryTabs?.length"
                         cssClass="data-category-tabstrip two-line-tabs"
                         [tabs]="dataCategoryTabs"
                         [selected]="selectedDataCategoryTab"
                         [onSelect]="onDataCategoryTabSelect.bind(this)">
        </app-simple-tabs>
      </div>
    </div>
  </header>
  <kendo-tabstrip *ngIf="tabs?.length" class="data-grid tabstrip two-line-tabs"
                  (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let tab of tabs"
                        [title]="tab.title"
                        [selected]="tab.title === selectedTab.title">
        <ng-template kendoTabContent>
          <app-simple-grid [columns]="gridColumns"
                           [hiddenColumns]="hiddenColumns"
                           [data]="data"
                           [alternatingColumnBg]="false"
                           rowSelectType="single"
                           [showRowSelect]="false"
                           [rowSelectCallback]="onGridRowSelect.bind(this)"
                           [emptyPlaceholder]="emptyPlaceholder"
                           [selectedRowIndexes]="selectedGridRowIndexes">
          </app-simple-grid>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <section *ngIf="data?.length" class="detail">
    <div class="column index-chart-column">
      <app-grouped-bar-chart class="chart grouped-bar-chart padded-bottom"
                             [groups]="ogrPerTrayChartGroups"
                             [data]="ogrPerTrayChartData"
                             [yAxis]="{ label: 'OGR/Tray', unit: '$' }"
                             [minValue]="0"
                             [precision]="4"
                             [showLegend]="false"
                             [showGroupSelector]="false"
                             noDataSubMessage=""
                             [selectedGroup]="selectedIndexChartGroup"
                             [selectedBarIndex]="selectedIndexChartBarIndex"
                             [selectedLegendIndex]="selectedIndexChartBarIndex"
                             [mouseOverCallback]="indexChartMouseOverCallback.bind(this)"
                             [mouseOutCallback]="indexChartMouseOutCallback.bind(this)"></app-grouped-bar-chart>
      <app-grouped-bar-chart class="chart grouped-bar-chart one-line-legend-and-group-select"
                             [groups]="ogrPerHaChartGroups"
                             [data]="ogrPerHaChartData"
                             [yAxis]="{ label: 'OGR/Ha', unit: '$' }"
                             [minValue]="0"
                             [precision]="0"
                             legendLocation="bottom"
                             noDataSubMessage=""
                             [selectedBarIndex]="selectedIndexChartBarIndex"
                             [selectedLegendIndex]="selectedIndexChartBarIndex"
                             [mouseOverCallback]="indexChartMouseOverCallback.bind(this)"
                             [mouseOutCallback]="indexChartMouseOutCallback.bind(this)"
                             [switchGroupCallback]="switchIndexChartGroupCallback.bind(this)"></app-grouped-bar-chart>
    </div>
    <div [class]="'column data-category ' + selectedDataCategoryTab.cssClass">
      <app-help-button class="help-button" [helpKey]="selectedDataCategoryTab.cssClass + '-chart'"></app-help-button>
      <div class="pages">
        <div class="page fruit-payments">
          <!--
            The chart presence in the dom is controlled by their proximity to the selected tab.
            Only charts immediately before and after the selected tab are included.
          -->
          <app-grouped-bar-chart-with-history *ngIf="selectedDataCategoryTab.index < 2"
                                              [minValue]="0"
                                              [baseSeason]="selectedSeason"
                                              [groups]="fruitPaymentsChartGroups"
                                              [data]="fruitPaymentsChartData"
                                              [yAxis]="{ label: 'Fruit Payments', unit: '$' }"></app-grouped-bar-chart-with-history>
        </div>
        <div class="page service-payments">
          <app-grouped-bar-chart-with-history *ngIf="selectedDataCategoryTab.index < 3"
                                              [minValueIfAllValuesArePositive]="0"
                                              [baseSeason]="selectedSeason"
                                              [groups]="servicePaymentsChartGroups"
                                              [data]="servicePaymentsChartData"
                                              [yAxis]="{ label: 'Service Payments', unit: '$' }"></app-grouped-bar-chart-with-history>
        </div>
        <div class="page packing-costs">
          <app-grouped-bar-chart-with-history *ngIf="selectedDataCategoryTab.index < 4 && selectedDataCategoryTab.index > 0"
                                              [maxValue]="0"
                                              [baseSeason]="selectedSeason"
                                              [groups]="packingCostsChartGroups"
                                              [data]="packingCostsChartData"
                                              [yAxis]="{ label: 'Packing Post Harvest Costs', unit: '$' }"></app-grouped-bar-chart-with-history>
        </div>
        <div class="page sundry-costs">
          <app-grouped-bar-chart-with-history *ngIf="selectedDataCategoryTab.index < 5 && selectedDataCategoryTab.index > 1"
                                              [maxValue]="0"
                                              [baseSeason]="selectedSeason"
                                              [groups]="sundryCostsChartGroups"
                                              [data]="sundryCostsChartData"
                                              [yAxis]="{ label: 'Sundry Post Harvest Costs', unit: '$' }"></app-grouped-bar-chart-with-history>
        </div>
        <div class="page net-time-and-storage">
          <app-grouped-bar-chart-with-history *ngIf="selectedDataCategoryTab.index > 2"
                                              [minValueIfAllValuesArePositive]="0"
                                              [baseSeason]="selectedSeason"
                                              [groups]="netTimeAndStorageChartGroups"
                                              [data]="netTimeAndStorageChartData"
                                              [yAxis]="{ label: 'Net Time & Storage', unit: '$' }"></app-grouped-bar-chart-with-history>
        </div>
        <div class="page sundry-payments">
          <app-grouped-bar-chart-with-history *ngIf="selectedDataCategoryTab.index > 3"
                                              [minValue]="0"
                                              [baseSeason]="selectedSeason"
                                              [groups]="sundryPaymentsChartGroups"
                                              [data]="sundryPaymentsChartData"
                                              [yAxis]="{ label: 'Sundry Payments', unit: '$' }"></app-grouped-bar-chart-with-history>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="data?.length"
     [class]="'sidebar ' + selectedDataCategoryTab.cssClass">
  <div class="sidebar-handle" (click)="toggleSidebar()"></div>
  <div class="sidebar-content">
    <div class="static-content-panel income-components-panel">
      <h4 class="sidebar-header">Income Components</h4>
      <app-pie-chart class="income-components-chart"
                     [width]="pieChartSize"
                     valuePostfix="%"
                     [showLegend]="true"
                     [showValues]="true"
                     [thickness]="9"
                     [showValuesInLegend]="true"
                     [height]="pieChartSize"
                     [data]="incomeComponentsChartData"></app-pie-chart>
    </div>
    <div class="pages">
      <div class="page fruit-payments">
        <h4 class="sidebar-header">
         Fruit Payments
        </h4>
        <div class="panel explanation-panel">
          <p>Fruit Payments include payments for the following:</p>
          <div class="column">
            <div class="column-header">Submit & Progress</div>
            <div class="column-explanation">
              Individual fruit values (includes G3 Organic Premium).
            </div>
          </div>
          <div class="column">
            <div class="column-header">KiwiStart</div>
            Individual KiwiStart per qualifying tray.
          </div>
          <div class="column">
            <div class="column-header">Taste Zespri</div>
            Individual Taste Zespri premium based on TZG.
          </div>
        </div>
      </div>
      <div class="page service-payments">
        <h4 class="sidebar-header">
          Service Payments
        </h4>
        <div class="panel explanation-panel">
          <p>Service Payments include income from the following sources:</p>
          <div class="column">
            <div class="column-header">Packtype Differential</div>
            <div class="column-explanation">
              This is a Zespri payment for packing fruit into any packtype other than a modular bulk pack.
              This is to ensure all growers/suppliers are treated equally.
            </div>
          </div>
          <div class="column">
            <div class="column-header">Time Service Payment</div>
            <div class="column-explanation">
              Income received for incremental coolstorage, coolstorage for fruit loss, repacking and condition checking.
              Only main pack pool trays share this income.
            </div>
          </div>
          <div class="column">
            <div class="column-header">Storage Incentives</div>
            <div class="column-explanation">
              Storage incentives earned. Pool splits apply to this income centre.
            </div>
          </div>
          <div class="column">
            <div class="column-header">Supplier Accountability</div>
            <div class="column-explanation">
              Estimated total of premiums and penalties earned for quality of fruit delivered to the market.
            </div>
          </div>
        </div>
      </div>

      <div class="page packing-costs">
        <h4 class="sidebar-header">
          Packing Post Harvest Costs
        </h4>
        <div class="page-content">
          <div class="panel">
            <app-pie-chart [width]="pieChartSize"
                           valuePostfix="%"
                           [showLegend]="true"
                           [showValues]="true"
                           [thickness]="9"
                           [showValuesInLegend]="true"
                           [height]="pieChartSize"
                           [data]="packingCostsSidebarChartData"></app-pie-chart>
          </div>
          <div class="panel explanation-panel">
            <p>Packing Post Harvest Costs include the following:</p>
            <div class="column">
              <div class="column-header">Base Pack</div>
              <div class="column-explanation">
                Charge for packing a tray.
              </div>
            </div>
            <div *ngIf="selectedSeason.value >= this.showExplosiveChargesSeasons.start">
              <div class="column">
                <div class="column-header">Explosive Charges</div>
                <div class="column-explanation">
                  Charge for explosive fruit at bin tip.
                </div>
              </div>
            </div>
            <div class="column">
              <div class="column-header">Reject Surcharge</div>
              <div class="column-explanation">
                Charge for % of reject fruit.
              </div>
            </div>
            <div class="column">
              <div class="column-header">Packing Differential</div>
              <div class="column-explanation">
                The cost of packing into any packtype other than a modular bulk pack.
              </div>
            </div>
            <div class="column">
              <div class="column-header">Base Coolstorage</div>
              <div class="column-explanation">
                Base coolstorage - only charged on loaded out trays (FOBS).
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page sundry-costs">
        <h4 class="sidebar-header">
          Sundry Post Harvest Costs
        </h4>
        <div class="page-content">
          <div class="panel">
            <app-pie-chart [width]="pieChartSize"
                           valuePostfix="%"
                           [showLegend]="true"
                           [showValues]="true"
                           [thickness]="9"
                           [showValuesInLegend]="true"
                           [height]="pieChartSize"
                           [data]="sundryCostsSidebarChartData"></app-pie-chart>
          </div>
          <div class="panel explanation-panel">
            <p>Sundry Post Harvest Costs include the following:</p>
            <div class="column">
              <div class="column-header">Administration</div>
              <div class="column-explanation">
                The cost of administering the TGL pools.
              </div>
            </div>
            <div *ngIf="selectedSeason.value >= this.showTglHailTopUpSeasons.start && selectedSeason.value < this.showTglHailTopUpSeasons.end">
              <div class="column">
                <div class="column-header">TGL Hail Top Up Insurance</div>
                <div class="column-explanation">
                  The hail top up insurance offered by TGL.
                </div>
              </div>
            </div>
            <div class="column">
              <div class="column-header">Logistics</div>
              <div class="column-explanation">
                The cost of getting a packed tray from coolstore door to being loaded on the ship (FOBS), and other sundry expenses.
                This also includes charges for legal, accounting, directors fees, directors liability insurance, hail insurance, software costs, picking bonus, low explosive fruit incentive and sundry freight.
              </div>
            </div>
            <div class="column-group" [class.excluded]="selectedSeason.value >= this.showCartageAndMaTestsExcludedSeasons.start">
              <h5 class="excluded-heading">Excluded From OGR</h5>
              <div class="column">
                <div class="column-header">Cartage</div>
                <div class="column-explanation">
                  Cost of carting your bins from your orchard to the packhouse.
                </div>
              </div>
              <div class="column">
                <div class="column-header">Maturity Tests</div>
                <div class="column-explanation">
                  Charges for maturity clearance testing prior to harvest.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page net-time-and-storage">
        <h4 class="sidebar-header">
          Net Time & Storage
        </h4>
        <div class="panel explanation-panel">
            <p>
              The costs and payments which make up total net time & storage are grouped together so that you can easily view your net time & storage.
              They consist of the following:
            </p>
            <div class="column">
              <div class="column-header">Time Service Payment</div>
              <div class="column-explanation">
                Income received for incremental coolstorage, coolstorage for fruit loss, repacking and condition checking.
                Only main pack pool trays share this income.
              </div>
            </div>
            <div class="column">
              <div class="column-header">Storage Incentives</div>
              <div class="column-explanation">
                Storage incentives earned. Pool splits apply to this income centre.
              </div>
            </div>
            <div class="column">
              <div class="column-header">Onshore Fruit Loss</div>
              <div class="column-explanation">
                Fruit loss incurred after submitting to Zespri.
              </div>
            </div>
            <div class="column">
              <div class="column-header">Time & CC/RK Charges</div>
              <div class="column-explanation">
                Charges for incremental coolstorage, repacking and condition checking and repalletisation.
                Only main pack pool trays share this income.
              </div>
            </div>
            <div class="column">
              <div class="column-header">CA & Bin Store Compensation</div>
              <div class="column-explanation">
                Distribution of CA and Bin Store Incentives. Qualifying CA fruit also receives Zespri compensation for
                weight loss.
              </div>
            </div>
            <div class="column">
              <div class="column-header">CA & Bin Store Charges</div>
              <div class="column-explanation">
                Cost to the pool for the CA & Bin Store Incentives. Only main pack pool trays share this cost.
              </div>
            </div>
        </div>
      </div>
      <div class="page sundry-payments">
        <h4 class="sidebar-header">
          Sundry Payments
        </h4>
        <div class="panel explanation-panel">
            <div class="column">
              <div class="column-header">Loyalty Premium</div>
              <div class="column-explanation">
                Premium paid on loaded out trays to all growers with a signed loyalty contract.
                Growers who have this paid through the pool share the impact of fruit loss with the pool.
              </div>
            </div>
            <div class="column">
              <div class="column-header">Class 2 Return</div>
              <div class="column-explanation">
                Returns for sale of class 2 fruit by Zespri, NutriKiwi, The Golden Kiwi Company and local market.
              </div>
            </div>
            <div class="column">
              <div class="column-header">NSS</div>
              <div class="column-explanation">
                Non-standard supply returns.
              </div>
            </div>

            <div *ngIf="selectedSeason.value < this.showJapanPremiumIncentiveSeasons.end">
              <div class="column">
                <div class="column-header">Japan Premium</div>
                <div class="column-explanation">
                  Premium for Japan supply paid to the Gold3 organic pool.
                </div>
              </div>
            </div>

            <div *ngIf="selectedSeason.value >= this.showJapanAndTaiwanPremiumIncentiveSeasons.start">
              <div class="column">
                <div class="column-header">Japan & Taiwan Premium</div>
                <div class="column-explanation">
                  Premium for Japan & Taiwan supply paid to the Organic pools.
                </div>
              </div>
            </div>

            <div *ngIf="selectedSeason.value >= this.showKiwigreenIncentiveSeasons.start && selectedSeason.value < this.showKiwigreenIncentiveSeasons.end">
              <div class="column">
                <div class="column-header">KiwiGreen Incentive</div>
                <div class="column-explanation">
                  Zespri incentive to support good Pest Management practices on orchard.
                </div>
              </div>
            </div>

            <div *ngIf="selectedSeason.value >= this.showHailCompensationSeasons.start && selectedSeason.value < this.showHailCompensationSeasons.end">
              <div class="column">
                <div class="column-header">Hail Compensation</div>
                <div class="column-explanation">
                  Compensation for accepted hail damaged fruit claims.
                </div>
              </div>
            </div>

            <div class="column">
              <div class="column-header">Other Income</div>
              <div class="column-explanation">
                Class 3 returns, used packaging, and DIFOTIS.
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="controls">
      <button *ngIf="selectedItem && selectedItem['ogr_forecast_file_id']"
              class="button fill report-button"
              [class.flash]="isOgrReportButtonFlashing"
              (click)="openOgrReport()">
        Download OGR Report
      </button>
    </div>
  </div>
</div>

<loading-indicator [class.visible]="isLoading"
                   [radius]="30"
                   [thickness]="4"
                   message=""
                   [isLight]="true"
                   [externalMessage]="mainLoadingMessage"
                   [isAbsolute]="true">
</loading-indicator>

<div class="no-data-message large" [class.visible]="!isLoading && !availableSeasons?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">OGR data not found.</div>
  <div class="sub-message">No available seasons with OGR data found.</div>
</div>

<div *ngIf="availableSeasons && selectedSeason" class="no-data-message large" [class.visible]="!isLoading && !data?.length">
  <i class="fas fa-exclamation-triangle"></i>
  <div class="message">OGR data not found.</div>
  <div class="sub-message">Try selecting a different season?</div>
  <div class="controls">
    <dropdown class="large"
              [items]="availableSeasons"
              [current]="selectedSeason"
              handle="ogrDetailsNoDataSeasonDropdown"
              [highlightSelected]="true"
              (currentChange)="selectSeason($event)">
      <button class="button transparent rounded bordered">
        Season: {{ selectedSeason.label }}
        <i class="fas fa-chevron-right right"></i>
      </button>
    </dropdown>
  </div>
</div>
