export const environment = {
  production: true,
  APP_NAME: 'grower portal',
  API_BASE_URL: 'https://growers.trevelyan.co.nz/api2/v1/',
  WS_BASE_URL: 'wss://growers.trevelyan.co.nz/ws/',
  LEGACY_BASE_URL: null,
  CSRF_TOKEN: 'XXX',
  SENTRY_DSN: 'https://11f5cf8e70024cfdadedf8eecfdfafd6@sentry.trevelyan.co.nz/13',
  GOOGLE_ANALYTICS_TRACKING_CODE: 'G-B1FBT4JHZC',
  LOGIN_PARAM_NAME: 'email',
  LOGIN_HELP_NAME: 'your Grower Rep',
  COMMIT_HASH: '8c1724788f12'
};
