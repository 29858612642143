import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { OutsideClickService } from 'app/shared/services/outside-click.service';
import { AuthService, User } from 'app/shared/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { HttpService } from 'app/shared/services/http.service';
import { environment } from 'environments/environment';
import { Utils } from 'app/shared/utils';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';

const RETRY_LIMIT = 10;
const RETRY_WAIT = 1000;

interface MenuItem {
  label: string;
  url: string;
  desktopOnly?: boolean;
  item?: string;
  iconFg?: string;
  queryParams?: any;
}

interface MenuCategory {
  label: string;
  items: MenuItem[];
}

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss', './main-menu-media-queries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentUser: User = {} as User;
  @Input() hasSearch = true;
  @Input() hasAccountLink = true;
  @Input() menuCategories: MenuCategory[] = [];
  @Input() isMenuLoadRequired = true;
  @Input() noColumnRule = false;
  @Input() showIcons = true;
  @Input() quickMenuItems = [
    { label: 'harvest', icon: 'fa-shopping-basket', url: '/ip/harvest/harvest_schedule' },
    { label: 'production', icon: 'fa-industry', url: '/ip/harvest/production_schedule' },
    { label: 'lab', icon: 'fa-flask', url: '/ip/harvest/lab_schedule' },
    { label: 'bin forecast', icon: 'fa-cubes', url: '/season-statistics/bin-forecast' },
    // { label: 'reports', icon: 'fa-chart-line', url: '/ip/report_index' }
  ];

  currentUserName = '';
  userDropdownItems = [];
  isMainMenuOpen = false;
  isSearchPanelOpen = false;
  currentUrl = '';
  isLoading = false;
  isGrowerPortal = false;
  private retryCounter = 0;
  private loadMenuCategoriesSubscription: Subscription;
  private logoutSubscription: Subscription;
  private readonly routerEventsSubscription: Subscription;

  constructor(
    private outsideClickService: OutsideClickService,
    private authService: AuthService,
    private dialogService: DialogService,
    public router: Router,
    private http: HttpService,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isGrowerPortal = Utils.isGrowerPortal();
    this.outsideClickService.add('mainMenu', this.closeMainMenu, this);
    this.outsideClickService.add('searchPanel', this.closeSearchPanel, this);
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentUrl();
      }
    });
  }

  ngOnInit() {
    if (this.isMenuLoadRequired) {
      this.loadMenuCategories();
    }
  }

  ngOnDestroy() {
    this.routerEventsSubscription?.unsubscribe();
    this.logoutSubscription?.unsubscribe();
    this.loadMenuCategoriesSubscription?.unsubscribe();
  }

  private loadMenuCategories() {
    const url = environment.LEGACY_BASE_URL + 'ip2/api/main_menu';
    this.isLoading = true;
    this.loadMenuCategoriesSubscription = this.http.get(url, { noPrefix: true })
      .subscribe(
        (data: MenuCategory[]) => {
          this.setMenuCategories(data);
        },
        () => {
          if (this.retryCounter < RETRY_LIMIT) {
            this.retryCounter++;
            setTimeout(this.loadMenuCategories.bind(this), RETRY_WAIT);
          } else {
            this.displayErrorDialog('Failed to load main menu content.');
            this.menuCategories = null;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  private setMenuCategories(data: MenuCategory[]) {
    this.menuCategories = data;
    this.updateGrowerMenuItems();
    this.updatePestMonitoringMenuItems();
    this.updateAdminMenuItems();
    this.updateTaskScheduleMenuItems();
    this.updateQualityMenuItems();
    this.updatePeopleMenuItems();
    this.updateHarvestMenuItems();
    this.changeDetectorRef.markForCheck();
  }

  private updateQualityMenuItems() {
    const items = [];
    if (this.canViewR600Manipulator()) {
      items.push({ label: 'R600 Manipulator', url: '/r600/manipulator', icon: 'fak fa-solid-table-clock' });
    }
    items.push({ label: 'R600 Packhouse Rotation', url: '/r600/packhouse_rotation', icon: 'fak fa-solid-table-rotate' });
    this.createOrUpdateCategory(items as MenuItem[], 'Quality');
  }

  private updateTaskScheduleMenuItems() {
    const items = [
      { label: 'Team Schedule', url: '/team-scheduler', icon: 'fak fa-solid-calendar-circle-user' },
      { label: 'Task Schedule', url: '/task-scheduler', icon: 'fak fa-solid-calendar-circle-check' }
    ];
    this.createOrUpdateCategory(items as MenuItem[], 'Task Schedules');
  }

  private updatePestMonitoringMenuItems() {
    const pestMonitoringMenuItems = [
      { label: 'AvoGreen Monitoring', url: '/avogreen-monitoring', icon: 'fa-desktop' }
    ];
    this.createOrUpdateCategory(pestMonitoringMenuItems as MenuItem[], 'Pest Monitoring');
  }

  private updatePeopleMenuItems() {
    const peopleMenuItems = [
      { label: 'Mailing Lists', url: '/mailing-lists', icon: 'fa-paper-plane' }
    ];
    this.createOrUpdateCategory(peopleMenuItems as MenuItem[], 'People');
  }

  private updateGrowerMenuItems() {
    const growerMenuItems = [
      { label: 'Prospectives', url: '/new-grower', icon: 'fa-circle-question' }
    ];
    if (this.canModifyChangeRequests()) {
      growerMenuItems.unshift( { label: 'Change Requests', url: '/change-requests', icon: 'fa-user-pen' });
    }
    this.createOrUpdateCategory(growerMenuItems as MenuItem[], 'Growers');
  }

  private updateHarvestMenuItems() {
    const harvestMenuItems = [
      { label: 'Season Stats', url: '/season-statistics', icon: 'fa-chart-simple' },
      { label: 'Bin Forecast', url: '/season-statistics/bin-forecast', icon: 'fa-cubes' },
      { label: 'Crop Estimation', url: '/season-statistics/crop-estimation', icon: 'fak fa-solid-leaf-circle-question' },
      { label: 'KiwiStart Allocations', url: '/kiwistart-allocations', icon: 'fa-arrow-down-1-9' }
    ];
    this.createOrUpdateCategory(harvestMenuItems as MenuItem[], 'Harvest');
  }

  private updateAdminMenuItems() {
    const adminMenuItems = [];

    if (this.canModifyDocuments()) {
      adminMenuItems.push({ label: 'Document Library', url: '/document-library', icon: 'fa-book' });
    }

    if (this.canImportData()) {
      adminMenuItems.push({ label: 'Data Importer', url: '/data-importer', icon: 'fa-download' });
    }

    if (this.canModifyOrchardCalendar()) {
      adminMenuItems.push({ label: 'Orchard Calendar', url: '/orchard-calendar', icon: 'fa-calendar' });
    }

    adminMenuItems.push({ label: 'Orchard Hazards', url: '/orchard-hazards', icon: 'fa-exclamation-triangle' });

    if (this.canAccessModelDocumentation()) {
      adminMenuItems.push({ label: 'Model Documentation', url: '/developer/model-documentation', icon: 'fa-database' });
    }

    if (adminMenuItems.length) {
      this.createOrUpdateCategory(adminMenuItems, 'Admin');
    }
  }

  private createOrUpdateCategory(menuItems: MenuItem[], categoryLabel: string): void {
    let category = this.findMenuCategory(categoryLabel);
    if (!category) {
      category = { label: categoryLabel, items: [] };
      this.menuCategories.push(category);
    }
    category.items = category.items.concat(menuItems);
  }

  private findMenuCategory(categoryLabel: string): MenuCategory {
    return this.menuCategories.find((category) => {
      return category.label === categoryLabel;
    });
  }

  private displayErrorDialog(message: string) {
    this.dialogService.open({
      title: 'Error',
      content: message,
      actions: [{ text: 'OK' }],
      width: 400
    });
  }

  menuLinkClick(event) {
    const url = event.target.pathname;
    const queryParams = Utils.getQueryParams(event.target.search);

    if (event.target.target === '_blank') {
      window.open(environment.LEGACY_BASE_URL + event.target.pathname.replace(/^\/ip/, 'ip2'));
    } else {
      // IMPORTANT! This is ugly but it does prevent a route change from setting duplicate history states
      // When the pathname is the same angular processes history differently.
      // See [ui/node_modules/@angular/router/@angular/router.es5.js, line approx 4162 (in Router.prototype.runNavigate)]
      const replaceUrl = window.location.pathname === url;
      queryParams.ae1c = Math.random().toString().slice(2, 7);
      this.router.navigate([url], { queryParams: queryParams, replaceUrl: replaceUrl });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser) {
      this.currentUser = changes.currentUser.currentValue;
      this.currentUserName = this.fullName(this.currentUser);
      this.updateUserDropdownItems();
    }
  }

  updateUserDropdownItems() {
    if (this.hasAccountLink) {
      this.userDropdownItems = [
        { label: this.currentUserName, header: true },
        { divider: true },
        { label: 'Account Settings', value: 'account-settings', href: '/ip/user/' + this.currentUser.id },
        { divider: true },
        { label: 'Logout', value: 'logout' }
      ];
    } else {
      this.userDropdownItems = [
        { label: this.currentUserName, header: true },
        { divider: true },
        { label: 'Logout', value: 'logout' }
      ];
    }
  }

  fullName(user: { firstname: string, surname: string }): string {
    return [user.firstname, user.surname].join(' ');
  }

  toggleMainMenu(event) {
    this.outsideClickService.executeAllExcept('mainMenu', event);
    this.isMainMenuOpen = !this.isMainMenuOpen;
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  toggleSearchPanel() {
    // Trigger "/" keydown which is bound to show the global search component.
    document.dispatchEvent(new KeyboardEvent('keydown', { key: '/' }));
  }

  closeOwnDropdowns(event) {
    this.outsideClickService.execute('searchTypeDropdown', event);
    event.stopPropagation();
  }

  logout() {
    // Call the logout route
    const url = environment.API_BASE_URL + 'auth/logout';
    this.logoutSubscription = this.http.get(url, { responseType: 'text', noPrefix: true })
      .subscribe(
        () => this.localLogout(),
        () => this.displayErrorDialog('Could not logout at this time.')
      );
  }

  localLogout() {
    this.currentUser = null;
    this.currentUserName = '';
    this.authService.clear();
    this.router.navigate(['/login']);
  }

  userMenuAction(event) {
    if (event.value === 'logout') {
      this.logout();
    } else if (event.href) {
      this.router.navigate([event.href]);
    }
  }

  closeMainMenu() {
    this.isMainMenuOpen = false;
    this.changeDetectorRef.markForCheck();
  }

  stopMenuClose(event) {
    if (event.target.tagName.toLowerCase() !== 'a') {
      event.stopPropagation();
    }
    return false;
  }

  private updateCurrentUrl() {
    if (this.router.url) {
      this.currentUrl = this.router.url.split('?')[0];
    }
  }

  private closeSearchPanel(event) {
    if (event.target.className !== 'button toggle') {
      this.isSearchPanelOpen = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  private canModifyDocuments() {
    return this.authService.hasPermission('isDocumentUploader');
  }

  private canImportData() {
    return this.authService.hasPermission('isDataImporter');
  }

  private canModifyOrchardCalendar() {
    return this.authService.hasPermission('isOrchardCalendarEditor');
  }

  private canAccessModelDocumentation() {
    return this.authService.hasPermission('canAccessModelDocumentation');
  }

  private canViewR600Manipulator() {
    return this.authService.hasPermission('canEditR600Data') ||
      this.authService.hasPermission('canViewHistoricalR600Data');
  }

  private canModifyChangeRequests() {
    return this.authService.hasPermission('canModifyChangeRequests');
  }
}
